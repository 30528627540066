import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import PictureButton from '../../../Model/EventDetailModel/FarEastThrowdownOnlineModel/PictureButton';
import SizeButton from '../../../Model/EventDetailModel/FarEastThrowdownOnlineModel/SizeButton';
import SuccessSelectBox from '../../../Component/EventApplicationComponent/SuccessSelectBox';

export default function TeamOfFourSubmitModel(props) {
  const {
    eventId,
    section,

    isSuccess,
    setIsSuccess,

    min,
    setMin,
    sec,
    setSec,

    tieBreakMin,
    setTieBreakMin,

    tieBreakSec,
    setTieBreakSec,

    distance,
    setDistance,

    completeRep,
    setCompleteRep,
    completeRound,
    setCompleteRound,
    setLastExercise,
    lastExerciseRep,
    setLastExerciseRep,
    firstExercise,
    setFirstExercise,
    secondExercise,
    setSecondExercise,
    thirdExercise,
    setThirdExercise,
    bodyWeight,
    setBodyWeight,
    point,
    setPoint,

    link,
    setLink,
    imgBase64,
    setImgBase64
  } = props;

  const { t } = useTranslation();

  // // 총 점수 산출 표출 //
  // useEffect(() => {
  //   if (bodyWeight === 0 || bodyWeight === '') setPoint(0);
  //   else {
  //     let totalWeight = 0;

  //     if (firstExercise !== '') totalWeight += parseFloat(firstExercise);
  //     if (secondExercise !== '') totalWeight += parseFloat(secondExercise);
  //     if (thirdExercise !== '') totalWeight += parseFloat(thirdExercise);

  //     if (totalWeight === 0) setPoint(0);
  //     else {
  //       let finalPoint = totalWeight - (bodyWeight * 1.5);

  //       if (finalPoint < 0) setPoint(0);
  //       else setPoint(finalPoint);
  //     }
  //   }
  // }, [firstExercise, secondExercise, thirdExercise, bodyWeight])

  // 기록된 시간중 분 데이터 변경시 //
  function minHandleChange(e) {
    let check = /^[0-9]+$/;

    if (check.test(e.target.value)) setMin(e.target.value);
    if (e.target.value === '') setMin('');
  }

  // 기록된 시간중 분 데이터 포커싱시 //
  function minHandleFocus(e) {
    setMin('');
  }

  // 기록된 시간중 초 데이터 변경시 //
  function secHandleChange(e) {
    // setSec(e.target.value);


    let check = /^[\d]*\.?[\d]{0,2}$/;

    if (check.test(e.target.value)) setSec(e.target.value);
    if (e.target.value === '') setSec('');
  }

  // 기록된 시간중 초 데이터 포커싱시 //
  function secHandleFocus(e) {
    setSec('');
  }


  // Tie Break 분 데이터 변경시 //
  function tieBreakMinHandleChange(e) {
    let check = /^[0-9]+$/;

    if (check.test(e.target.value)) setTieBreakMin(e.target.value);
    if (e.target.value === '') setTieBreakMin('');
  }

  // Tie Break 분 데이터 포커싱시 //
  function tieBreakMinHandleFocus(e) {
    setTieBreakMin('');
  }

  // Tie Break 초 데이터 변경시 //
  function tieBreakSecHandleChange(e) {
    let check = /^[\d]*\.?[\d]{0,2}$/;

    if (check.test(e.target.value)) setTieBreakSec(e.target.value);
    if (e.target.value === '') setTieBreakSec('');
  }

  // Tie Break 초 데이터 포커싱시 //
  function tieBreakSecHandleFocus(e) {
    setTieBreakSec('');
  }


  // 타임캡 오버시 로잉 길이 포커싱시 //
  function distanceHandleFocus(e) {
    setDistance('');
  }

  // 타임캡 오버시 로잉 길이 수정시 //
  function distanceHandleChange(e) {
    let check = /^[\d]*\.?[\d]{0,2}$/;

    if (check.test(e.target.value)) setDistance(e.target.value);
    if (e.target.value === '') setDistance('');
  }

  // 완벽하게 끝낸 REP 데이터 변경시 //
  function repHandleChange(e) {
    // setCompleteRep(e.target.value);

    let check = /^[0-9]+$/;

    if (check.test(e.target.value)) setCompleteRep(parseInt(e.target.value));
    if (e.target.value === '') setCompleteRep('');
  }

  // 완벽하게 끝낸 REP 데이터 포커싱시 //
  function repHandleFocus(e) {
    setCompleteRep('');
  }

  // 완벽하게 끝낸 라운드 데이터 변경시 //
  function roundHandleChange(e) {
    // setCompleteRound(e.target.value);


    let check = /^[0-9]+$/;

    if (check.test(e.target.value)) setCompleteRound(e.target.value);
    if (e.target.value === '') setCompleteRound('');
  }

  // 완벽하게 끝낸 라운드 데이터 포커싱시 //
  function roundHandleFocus(e) {
    setCompleteRound('');
  }

  // 마지막 운동 개수 데이터 변경시 //
  function lastExerciseHandleChange(e) {
    let check = /^[0-9]+$/;

    if (check.test(e.target.value)) setLastExerciseRep(e.target.value);
    if (e.target.value === '') setLastExerciseRep('');
  }

  // 마지막 운동 개수 데이터 포커싱시 //
  function lastExerciseHandleFocus(e) {
    setLastExerciseRep('');
  }

  // 첫번째 운동 무게 데이터 변경시 //
  function firstExerciseHandleChange(e) {

    let check = /^[\d]*\.?[\d]{0,2}$/;

    if (check.test(e.target.value)) setFirstExercise(e.target.value);
    if (e.target.value === '') setFirstExercise('');
  }

  // 첫번째 운동 무게 데이터 포커싱시 //
  function firstExerciseHandleFocus(e) {
    setFirstExercise('');
  }

  // 두번째 운동 무게 데이터 변경시 //
  function secondExerciseHandleChange(e) {

    let check = /^[\d]*\.?[\d]{0,2}$/;

    if (check.test(e.target.value)) setSecondExercise(e.target.value);
    if (e.target.value === '') setSecondExercise('');
  }

  // 두번째 운동 무게 데이터 포커싱시 //
  function secondExerciseHandleFocus(e) {
    setSecondExercise('');
  }

  // 세번째 운동 무게 데이터 변경시 //
  function thirdExerciseHandleChange(e) {

    let check = /^[\d]*\.?[\d]{0,2}$/;

    if (check.test(e.target.value)) setThirdExercise(e.target.value);
    if (e.target.value === '') setThirdExercise('');
  }

  // 세번째 운동 데이터 포커싱시 //
  function thirdExerciseHandleFocus(e) {
    setThirdExercise('');
  }

  // 몸무게 데이터 변경시 //
  function bodyWeightHandleChange(e) {

    let check = /^[0-9]+$/;

    if (check.test(e.target.value)) setBodyWeight(e.target.value);
    if (e.target.value === '') setBodyWeight('');
  }

  // 몸무게 데이터 포커싱시 //
  function bodyWeightHandleFocus(e) {
    setBodyWeight('');
  }

  // 유튜브 링크 데이터 변경시 //
  function linkHandleChange(e) {
    setLink(e.target.value);
  }

  return (
    <TopLevelWrapper>
      <TopTitleWrapper>
        <TopTitleInnerWrapper>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{t('기록하기')}</Text>
        </TopTitleInnerWrapper>
      </TopTitleWrapper>
      <InnerWrapper>
        {
          (section === 'A') &&
          <ComponentWrapper>
            <EventDivisionBox>
              <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>EVENT 1</Text>
            </EventDivisionBox>
            {/* <ComponentInnerWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('동작을 성공적으로 완료하셨나요?')}</Text>
              <SuccessSelectBox unit={isSuccess} setUnit={setIsSuccess} />
            </ComponentInnerWrapper> */}
            <AdditionComponentWrapper>
              <ComponentInnerWrapper style={{ marginTop: `12px` }}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>얼마나 많은 랩 수를 성공했나요?</Text>
                <DistanceInputBox>
                  <DistanceInput
                    value={completeRep}
                    type='decimal'
                    onFocus={repHandleFocus}
                    onChange={repHandleChange} />
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>REP</Text>
                </DistanceInputBox>
              </ComponentInnerWrapper>
              <ComponentInnerWrapper style={{ marginTop: `12px` }}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>Tie Break: 4R 끝난 시간</Text>
                <TimeInputWrapper>
                  <TimeBox>
                    <TimeInput
                      value={tieBreakMin}
                      type='number'
                      pattern='\d*'
                      onFocus={tieBreakMinHandleFocus}
                      onChange={tieBreakMinHandleChange} />
                    <Text style={{ marginRight: '12px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>min</Text>
                  </TimeBox>
                  <TimeBox>
                    <TimeInput
                      value={tieBreakSec}
                      type='decimal'
                      onFocus={tieBreakSecHandleFocus}
                      onChange={tieBreakSecHandleChange} />
                    <Text style={{ marginRight: '12px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>sec</Text>
                  </TimeBox>
                </TimeInputWrapper>
              </ComponentInnerWrapper>
            </AdditionComponentWrapper>
          </ComponentWrapper>
        }
        {
          (section === 'B' || section === 'C') &&
          <ComponentWrapper>
            <AdditionComponentWrapper>
              <EventDivisionBox>
                <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>{section === 'B' ? `EVENT 2` : `EVENT 3`}</Text>
              </EventDivisionBox>
              <SuccessBoxWrapper style={{ marginTop: `12px` }}>
                <SuccssBox isClicked={isSuccess.value === '완료하였습니다'} onClick={() => setIsSuccess({ value: '완료하였습니다' })}>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>타임캡 안에 성공</Text>
                </SuccssBox>
                <FailBox isClicked={isSuccess.value === '타임켑안에 못했어요'} onClick={() => setIsSuccess({ value: '타임켑안에 못했어요' })}>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>타임캡 터치</Text>
                </FailBox>
              </SuccessBoxWrapper>
              {
                isSuccess.value === '완료하였습니다' &&
                <ComponentInnerWrapper style={{ marginTop: `12px` }}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>기록된 시간을 알려주세요</Text>
                  <TimeInputWrapper>
                    <TimeBox>
                      <TimeInput
                        value={min}
                        type='number'
                        pattern='\d*'
                        onFocus={minHandleFocus}
                        onChange={minHandleChange} />
                      <Text style={{ marginRight: '12px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>min</Text>
                    </TimeBox>
                    <TimeBox>
                      <TimeInput
                        value={sec}
                        type='decimal'
                        onFocus={secHandleFocus}
                        onChange={secHandleChange} />
                      <Text style={{ marginRight: '12px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>sec</Text>
                    </TimeBox>
                  </TimeInputWrapper>
                </ComponentInnerWrapper>
              }
              {
                isSuccess.value === '타임켑안에 못했어요' &&
                <ComponentInnerWrapper style={{ marginTop: `12px` }}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>얼마나 많은 랩 수를 했나요?</Text>
                  <DistanceInputBox>
                    <DistanceInput
                      value={completeRep}
                      type='decimal'
                      onFocus={repHandleFocus}
                      onChange={repHandleChange} />
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>REPS</Text>
                  </DistanceInputBox>
                </ComponentInnerWrapper>
              }
            </AdditionComponentWrapper>
          </ComponentWrapper>
        }
        {
          (section === 'D') &&
          <ComponentWrapper>
            <EventDivisionBox>
              <Text fontFamily='Poppins-Bold' fontSize={14} color='#FFFFFF'>EVENT 4</Text>
            </EventDivisionBox>
            <AdditionComponentWrapper>
              <ComponentInnerWrapper style={{ marginTop: `12px` }}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>얼마나 많은 point를 성공했나요?</Text>
                <DistanceInputBox>
                  <DistanceInput
                    value={completeRep}
                    type='decimal'
                    onFocus={repHandleFocus}
                    onChange={repHandleChange} />
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>Point</Text>
                </DistanceInputBox>
              </ComponentInnerWrapper>
              <ComponentInnerWrapper style={{ marginTop: `12px` }}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>Tie Break: 시간</Text>
                <TimeInputWrapper>
                  <TimeBox>
                    <TimeInput
                      value={tieBreakMin}
                      type='number'
                      pattern='\d*'
                      onFocus={tieBreakMinHandleFocus}
                      onChange={tieBreakMinHandleChange} />
                    <Text style={{ marginRight: '12px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>MIN</Text>
                  </TimeBox>
                  <TimeBox>
                    <TimeInput
                      value={tieBreakSec}
                      type='decimal'
                      onFocus={tieBreakSecHandleFocus}
                      onChange={tieBreakSecHandleChange} />
                    <Text style={{ marginRight: '12px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>SEC</Text>
                  </TimeBox>
                </TimeInputWrapper>
              </ComponentInnerWrapper>
            </AdditionComponentWrapper>
          </ComponentWrapper>
        }
        <ComponentWrapper>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>EVENT {t('기록 영상')}</Text>
          <InputBox>
            <VideoLinkInput
              value={link}
              type='text'
              placeholder={t('유튜브 영상 링크를 붙여넣어 주세요')}
              onChange={linkHandleChange} />
          </InputBox>
        </ComponentWrapper>
        <ComponentWrapper>
          <TextWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>{t('사진 추가')}</Text>
            <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#828282'>{t('( 최대 5개 추가 가능 )')}</Text>
          </TextWrapper>
          <PictureButton imgBase64={imgBase64} setImgBase64={setImgBase64} />
        </ComponentWrapper>
      </InnerWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // padding: 20px 0px 38px 0px;
  padding-bottom: 38px;

  width: 100%;

  background-color: #F9F9F9;
`;

const TopTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 15px 0px 5px 0px;

  width: 100%;
  
  background-color: #FFFFFF;
`;

const TopTitleInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;


const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;


const Event7Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 10px;

  width: 100%;

  gap: 8px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 4px;
`;

const DivisionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  gap: 13px;
`;

const ComponentInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: ${props => props.isFirst ? `20px` : `0px`};

  width: 100%;

  gap: 8px;
`;

const SuccessBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const SuccssBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 47.5%;
  height: 34px;

  border-radius: 12px;
  background-color: ${props => props.isClicked ? '#6DD49E' : '#CBCBCB'};

  transition: all 0.3s ease-in-out;
`;

const FailBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 47.5%;
  height: 34px;

  border-radius: 12px;
  background-color: ${props => props.isClicked ? '#D92828' : '#CBCBCB'};

  transition: all 0.3s ease-in-out;
`;

const AdditionComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const TimeInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TimeBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 48.39%;
  height: 32px;

	border: 1px solid #CBCBCB;
	border-radius: 12px;
	
	// background-color: #FFFFFF;
	background-color: #F6F6F6;
`;

const TimeInput = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	line-height: 20px;

	// width: calc(100% - 24px);
  width: 60%;
	height: 100%;

	padding: 0 12px 0 12px;

	outline: none;
	border: 0px solid #E8E8E8;
  border-radius: 12px;
  background-color: #F6F6F6;
	
	color: #000000;

	::placeholder {
		color: #CBCBCB;
	}
	
  transition: all 0.3s ease-in-out;
`;

const InputBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
	height: 32px;

	border: 1px solid #CBCBCB;
  border-radius: 12px;
  background-color: #F6F6F6;
`;

const VideoLinkInput = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	line-height: 20px;

	width: calc(100% - 24px);
  // width: 60%;
	// height: 38px;
  height: 100%;

	padding: 0 12px 0 12px;

	outline: none;
	border: 0px solid #E8E8E8;
  border-radius: 12px;
  background-color: #F6F6F6;
	
	color: #000000;

	::placeholder {
		color: #CBCBCB;
	}
	
  transition: all 0.3s ease-in-out;
`;

const HalfInputBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 47%;
	height: 38px;
  
	border: 1px solid #E8E8E8;
  border-radius: 12px;
  background-color: #FFFFFF;
`;

const HalfInput = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	line-height: 20px;

	// width: calc(48.39% - 24px);
	width: calc(100% - 24px);
	height: 38px;

	padding: 0 12px 0 12px;

	outline: none;
	border: 0px solid #E8E8E8;
  border-radius: 12px;
	
	color: #000000;

	::placeholder {
		color: #CBCBCB;
	}
	
  transition: all 0.3s ease-in-out;
`;

const FeelTextBox = styled.textarea`
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	line-height: 20px;

	width: calc(100% - 24px);
  // width: 60%;
	height: 131px;

	padding: 12px 12px 12px 12px;

	outline: none;
  resize: none;
	border: 1px solid #E8E8E8;
  border-radius: 12px;
	
	color: #000000;

	::placeholder {
		color: #CBCBCB;
	}
	
  transition: all 0.3s ease-in-out;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  gap: 8px;
`;

const ExerciseInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const NumberBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  background-color: #FF8B48;
`;

const GreenNumberBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  background-color: #6DD49E;
`;

const DistanceInputBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding-right: 12px;

  // width: calc(85% - 14px);
  width: calc(100% - 14px);
  height: 32px;

	border: 1px solid #CBCBCB;
  border-radius: 12px;
  background-color: #F6F6F6;
`;

const DistanceInput = styled.input`
  font-family: NotoSansKR-Regular;
  font-size: 14px;
  line-height: 20px;

  width: 70%;
  // height: 34px;
  height: 100%;

  padding: 0 12px 0 12px;

  outline: none;
  border: 0px solid #E8E8E8;
  border-radius: 12px;
  background-color: #F6F6F6;

  color: #000000;

  ::placeholder {
    color: #CBCBCB;
  }

`;

const WeightInputBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding-right: 12px;

  width: calc(85% - 14px);
  height: 38px;

	border: 1px solid #E8E8E8;
  border-radius: 12px;
  background-color: #FFFFFF;
`;

const WeightInput = styled.input`
  font-family: NotoSansKR-Regular;
  font-size: 14px;
  line-height: 20px;

  width: 70%;
  height: 38px;

  padding: 0 12px 0 12px;

  outline: none;
  border: 0px solid #E8E8E8;
  border-radius: 12px;

  color: #000000;

  ::placeholder {
    color: #CBCBCB;
  }

`;

const TotalWeightWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TotalWeightTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: calc(40.9% - 2px);
  width: calc(35% - 2px);
  height: 38px;

  gap: 2px;

  border: 1px solid #E0E0E0;
  border-radius: 12px;
  background-color: #FFFFFF;
`;

const RedLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 60%;
  height: 1px;

  background-color: #FF3131;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const EventDivisionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 63px;
  height: 32px;

  border-radius: 6px;
  background-color: #6DD49E;
`;